import React from "react";
import "./style.css";

export const OzmoAdventureLandLogo = ({ style }) => {
  return (
    <div
      className="ozmo-adventure-land-logo"
      style={{
        ...{
          backgroundImage: "url(/img/ozmo-al-1-2-2.png)",
        },
        ...style,
      }}
    />
  );
};

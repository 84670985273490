import React, { useState, useEffect } from 'react';
import { login, signInWithGoogle, signInWithFacebook, signInWithApple } from '../../utils/firebase';
import { useNavigate } from 'react-router-dom';
import { deleteUser } from "../../services/DeleteUser";
import {
  MDBContainer,
  MDBInput,
  MDBBtn,
}
  from 'mdb-react-ui-kit';

import "mdb-react-ui-kit/dist/css/mdb.min.css"

import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
const MySwal = withReactContent(Swal)

export default function Login() {
  const navigate = useNavigate();
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")) || null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));

    if (user) {

      if (user.stsTokenManager.expirationTime < Date.now()) {
        setUser(null);
        localStorage.removeItem("user");
        window.location.reload();
      } else {
        setUser(user);

      }
    }

  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const user = await login(email, password);

    console.log(email, password)

    if (user.error) {
      console.log(user.error.code)

      if (["auth/invalid-email", "auth/wrong-password", "auth/user-not-found"].indexOf(user.error.code) != -1 ) {
        setErrorMsg("Şifreniz veya E-Mail adresiniz yanlış, tekrar deneyiniz")
        setTimeout(() => { setErrorMsg("") }, 2000)
      }
      if (user.error.code === "auth/user-disabled") {
        setErrorMsg("Üyeliğiniz devre dışı olduğu için giriş yapamazsınız!");
      }
      return;
    }
    if (user) {
      localStorage.setItem("user", JSON.stringify(user))
      window.location.reload();
    }
  };

  const handleDelete = async () => {

    MySwal.fire({
      title: 'Uyarı',
      type: 'warning',
      text: `Üyeliğinizi silmek istediğinize emin misiniz?`,
      showCloseButton: true,
      showDenyButton: true,
      showConfirmButton: true,
      allowOutsideClick: false,
      confirmButtonText: 'Evet',
      denyButtonText: 'Hayır',
      allowEscapeKey: false,
      onClose: function () { },
  }).then(async (result) => {
      if (result.isConfirmed) {
        const token = user?.stsTokenManager?.accessToken;
        const userEmail = user?.email;
        const uid = user?.uid;
        const a = await deleteUser(userEmail, uid, token);

        MySwal.fire(
          'Uyarı',
          'Üyeliğiniz silinmiştir.',
          'success'
        ).then(async (result) => {
          window.location.href="/"
        });

      }
  });
  
  }
  const handleGoogleSignIn = async () => {
    const user = await signInWithGoogle();
    if (user.error) {
      if (user.error.code === "auth/user-not-found") {
        setErrorMsg("Kullanıcı bulunamadı.")
      }
      if (user.error.code === "auth/wrong-password") {
        setErrorMsg("Şifreniz yanlış, tekrar deneyiniz")
        setTimeout(() => { setErrorMsg("") }, 3000)
      }
      if (user.error.code === "auth/user-disabled") {
        setErrorMsg("Üyeliğiniz devre dışı olduğu için giriş yapamazsınız!");
      }
      return;
    }
    if (user) {
      localStorage.setItem("user", JSON.stringify(user))
      window.location.reload();
    }

  };

  const handleFacebookSignIn = async () => {
    const user = await signInWithFacebook();
    console.log(user);
    if (user) {
      localStorage.setItem("user", JSON.stringify(user))
      window.location.reload();
    }
  };

  const handleAppleSignIn = async () => {
    const user = await signInWithApple();
    console.log(user);
    if (user) {
      localStorage.setItem("user", JSON.stringify(user))
      window.location.reload();
    }
  };

  return (
    <>

        <MDBContainer className="p-3 my-5 d-flex flex-column w-50">


        {user ? <button onClick={() => handleDelete()} className='btn btn-danger '> Üyeliğinizi silmek için tıklayınız</button> : 
        <>

          <h1>Ozmo Oyun Üyeliği Silme Sayfası</h1>

          <p>Lütfen üyeliğinizi silmek istediğiniz hesaba giriş yapınız.</p>


          {errorMsg && <p className='text-danger'>{errorMsg}</p>}

          <MDBInput wrapperClass='mb-4' label='Email Adresiniz' id='email'
            name='email'
            type='text'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            autoComplete='email' />
          <MDBInput wrapperClass='mb-4' label='Şifreniz' id='password'
            name='password'
            type='password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            autoComplete='current-password' />

          <MDBBtn className="mb-4" type='submit'
                  disabled={!email || !password} onClick={handleSubmit}>Giriş Yap</MDBBtn>

          <div className="text-center">

            <div className='d-flex justify-content-between mx-auto' style={{ width: '80%' }}>

              <MDBBtn className="w-25 mb-4 mr-4" style={{width: "200px"}} onClick={() => { handleGoogleSignIn() }}>Google ile Giriş Yap</MDBBtn>
              <MDBBtn className="w-25 mb-4" style={{width: "200px"}} onClick={() => { handleAppleSignIn() }}>Apple ile Giriş Yap</MDBBtn>

            </div>
          </div>
          </>
}
        </MDBContainer>

 
    </>
  );
}

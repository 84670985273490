import logo from './logo.svg';
import './App.css';

import { Dashboard } from "./Dashboard";
import Router from './router';

function App() {
  return (
  <Router/>
  );
}

export default App;

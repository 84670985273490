import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const ImageGames = ({
  style,
  groupStyle,
  overlapStyle,
  overlapStyleOverride,
  groupStyleOverride,
  overlapGroupStyle,
  ellipseStyle,
  tarlStyle,
  tarl = "/img/tarl-1-1.png",
  group9Style,
  overlapGroup1Style,
  ellipseStyleOverride,
  maskGroupStyle,
  maskGroup = "/img/mask-group-11.png",
  group7Style,
  overlapGroup2Style,
  ellipse5Style,
  baskStyle,
  bask = "/img/bask-1-1.png",
}) => {
  return (
    <div className="image-games" style={style}>
      <div className="group-16" style={groupStyle}>
        <div className="overlap" style={overlapStyle}>
          <div className="overlap-group3" style={overlapStyleOverride}>
            <div className="group-15" style={groupStyleOverride}>
              <div className="overlap-group-2" style={overlapGroupStyle}>
                <div className="ellipse" style={ellipseStyle} />
                <img className="tarl" style={tarlStyle} alt={"Tarl"} src={tarl} />
              </div>
            </div>
            <div className="group-9" style={group9Style}>
              <div className="overlap-group1" style={overlapGroup1Style}>
                <div className="ellipse-4" style={ellipseStyleOverride} />
                <img className="mask-group-5" style={maskGroupStyle} alt={"Mask group"} src={maskGroup} />
              </div>
            </div>
          </div>
          <div className="group-7" style={group7Style}>
            <div className="overlap-group2" style={overlapGroup2Style}>
              <div className="ellipse-5" style={ellipse5Style} />
              <img className="bask" style={baskStyle} alt={"Bask"} src={bask} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ImageGames.propTypes = {
  tarl: PropTypes.string,
  maskGroup: PropTypes.string,
  bask: PropTypes.string,
};

import axios from "axios";
export const deleteUser = async (userEmail,uid,token) => {
    const url = 'https://api.ozmonunmaceradunyasi.com/api/user/delete';
    const headers = {
      Authorization: uid,
      'Content-Type': 'application/json'
    };
    
    const data = {
      userEmail,
      uid
    };
    
    axios.post(url, data, { headers })
      .then(response => {
       localStorage.removeItem("user");
      })
      .catch(error => {
        console.error('API error:', error);
      });
}
import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const DownloadOnTheAppStoreBadgeTr = ({
  style,
  downloadOnTheAppStoreBadgeTr = "/img/download-on-the-app-store-badge-tr-1.svg",
}) => {
  return (
    <img
      className="download-on-the-app-store-badge-TR"
      style={style}
      alt={"Download on the app store badge TR"}
      src={downloadOnTheAppStoreBadgeTr}
    />
  );
};

DownloadOnTheAppStoreBadgeTr.propTypes = {
  downloadOnTheAppStoreBadgeTr: PropTypes.string,
};

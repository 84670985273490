import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const GetOnTheGooglePlayBadgeTr = ({
  style,
  getOnTheGooglePlayBadgeTr = "/img/get-on-the-google-play-badge-tr-1.svg",
}) => {
  return (
    <img
      className="get-on-the-google-play-badge-TR"
      style={style}
      alt={"Get on the google play badge TR"}
      src={getOnTheGooglePlayBadgeTr}
    />
  );
};

GetOnTheGooglePlayBadgeTr.propTypes = {
  getOnTheGooglePlayBadgeTr: PropTypes.string,
};

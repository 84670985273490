import { initializeApp } from 'firebase/app';
import {
  getAuth,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  GoogleAuthProvider,
  FacebookAuthProvider,
  OAuthProvider,
  signInWithPopup,
} from 'firebase/auth';


initializeApp({
  apiKey: "AIzaSyDBVP51xEu_JO8fw0s35oiPKSjsM8XA53M",
  authDomain: "ozmonunmaceradunyasi.firebaseapp.com",
  databaseURL: "https://ozmonunmaceradunyasi-default-rtdb.firebaseio.com",
  projectId: "ozmonunmaceradunyasi",
  storageBucket: "ozmonunmaceradunyasi.appspot.com",
  messagingSenderId: "62160329578",
  appId: "1:62160329578:web:98db6d1a8fc3a4df902313",
  measurementId: "G-51F0HFDB71"
});

export const auth = getAuth();

export const login = async (email, password) => {
  try {
    const { user } = await signInWithEmailAndPassword(auth, email, password);

    return user;
  } catch (error) {
    return {error}
  }
};

// Google ile giriş
export const signInWithGoogle = async () => {
  try {
    const provider = new GoogleAuthProvider();
    const { user } = await signInWithPopup(auth, provider);
    return user;
  } catch (error) {
    return {error}
    // Hata yönetimi burada yapılabilir
  }
};

// Facebook ile giriş
export const signInWithFacebook = async () => {
  try {
    const provider = new FacebookAuthProvider();
    const { user } = await signInWithPopup(auth, provider);
    return user;
  } catch (error) {
    // Hata yönetimi burada yapılabilir
  }
};

// Apple ile giriş
export const signInWithApple = async () => {
  try {
    const provider = new OAuthProvider('apple.com');
    const { user } = await signInWithPopup(auth, provider);
    return user;
  } catch (error) {
    // Hata yönetimi burada yapılabilir
  }
};



onAuthStateChanged(auth, async (user) => {
  try {
    if (user) {
      const { accessToken, refreshToken, expirationTime } =
        auth.currentUser.stsTokenManager;
      const { displayName, email, photoURL, emailVerified, uid, providerId } =
        auth.currentUser;
      const userDetail = {
        displayName,
        email,
        photoURL,
        emailVerified,
        accessToken,
        uid,
        providerId,
        refreshToken,
        expirationTime,
      };

      if (expirationTime < Date.now()) {
        //refresh accessToken
        localStorage.removeItem("user");
       
      } else {
        localStorage.setItem("user".JSON.stringify(userDetail));
      }
    } else {
      localStorage.removeItem("user");
    }
  } catch (error) {
  }
});

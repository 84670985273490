import { Routes, Route, Navigate } from 'react-router-dom';
import Login from './components/Login/Login';
import { Dashboard } from './Dashboard';





function Router() {
  return (
    <>
      <Routes> 
      
          <Route path='/' element={<Dashboard/>}/>
          <Route path='/delete' element={<Login/>}/>
        
        
      </Routes> 
    
       
    </>
  );
}


export default Router;

import React, { useEffect, useState } from "react";
import { useWindowWidth } from "./breakpoints";
import { CardCloudsFooter } from "../components/CardCloudsFooter";
import { DownloadOnTheAppStoreBadgeTr } from "../components/DownloadOnTheAppStoreBadgeTr";
import { GetOnTheGooglePlayBadgeTr } from "../components/GetOnTheGooglePlayBadgeTr";
import { ImageBasket } from "../components/ImageBasket";
import { ImageCharacter } from "../components/ImageCharacter";
import { ImageClean } from "../components/ImageClean";
import { ImageEgg } from "../components/ImageEgg";
import { ImageGames } from "../components/ImageGames";
import { ImageMarket } from "../components/ImageMarket";
import { ImagePaint } from "../components/ImagePaint";
import { ImagePark } from "../components/ImagePark";
import { ImagePet } from "../components/ImagePet";
import { ImagePiano } from "../components/ImagePiano";
import { ImagePlant } from "../components/ImagePlant";
import { IonLogoGooglePlaystore } from "../components/IonLogoGooglePlaystore";
import { OzmoAdventureLandLogo } from "../components/OzmoAdventureLandLogo";
import { OzmoCharacter } from "../components/OzmoCharacter";
import { SimpleIconsAppstore } from "../components/SimpleIconsAppstore";
import ReactLanguageSelect from 'react-languages-select';
import 'react-languages-select/css/react-languages-select.css';

//import {Translator, Translate} from 'react-auto-translate';

import "./style.css";

export const Dashboard = () => {
  const screenWidth = useWindowWidth();
  console.log("screenwidth", screenWidth);

  const [language, setLanguage] = useState("tr")

  const onSelectLanguage = (lang) => {
    setLanguage(lang);
    console.log("selected", lang);
  }

  const getWord = (key) => {

    switch (key) {
      case "about":
        switch (language) {
          case "tr":
            return "Hakkında";
          case "en":
            return "About";
          case "ar":
            return "عن";
          case "ru":
            return "о нас";
        }
        break;

      case "contact":
        switch (language) {
          case "tr":
            return "İletişim";
          case "en":
            return "Contact Us";
          case "ar":
            return "اتصل بنا";
          case "ru":
            return "связаться с нами";
        }
        break;

      case "download":
        switch (language) {
          case "tr":
            return "İNDİR";
          case "en":
            return "DOWNLOAD";
          case "ar":
            return "عن";
          case "ru":
            return "скачать";
        }
        break;

      case "createchar":
        switch (language) {
          case "tr":
            return "Karakterini Yarat";
          case "en":
            return "Create Your Character";
          case "ar":
            return "اصنع شخصيتك";
          case "ru":
            return "Создайте своего персонажа";
        }
        break;

      case "gamesprices":
        switch (language) {
          case "tr":
            return "Oyunları Oyna, Ödülleri Topla";
          case "en":
            return "Play Games, Win Prices";
          case "ar":
            return "العب الألعاب واربح الأسعار";
          case "ru":
            return "Играйте в игры, выигрывайте цены";
        }
        break;

      case "spendpoints":
        switch (language) {
          case "tr":
            return "Puanlarını Harca";
          case "en":
            return "Spend Your Points";
          case "ar":
            return "أنفق نقاطك";
          case "ru":
            return "Потратьте свои очки";
        }
        break;

      case "createchar2":
        switch (language) {
          case "tr":
            return "En heyecan verici kısım.\n Kendi karakterini yaratabilir, saç stilini bile değiştirebilirsin!";
          case "en":
            return "The most exciting part\nYou can create your own character, even change the hairstyle!";
          case "ar":
            return "الجزء الأكثر إثارة \n يمكنك إنشاء شخصيتك الخاصة ، أو حتى تغيير تصفيفة الشعر!";
          case "ru":
            return "Самое интересное \n Вы можете создать своего персонажа и даже изменить прическу!";
        }
        break;


      case "gamesprices2":
        switch (language) {
          case "tr":
            return "Bu macera dolu dünya tamamen sana ait. İstediğin oyunu oynayabilirsin.";
          case "en":
            return "This adventurous world is entirely yours. You can play any game you want.";
          case "ar":
            return "عالم المغامرة هذا ملكك بالكامل. يمكنك لعب أي لعبة تريدها.";
          case "ru":
            return "Этот полный приключений мир полностью принадлежит вам. Вы можете играть в любую игру, которую хотите.";
        }
        break;


      case "spendpoints2":
        switch (language) {
          case "tr":
            return "Yeni eşyalar alabilirsin. Tüm bunlar, Ozmo’nun eğlenceli dükkanında seni bekliyor!";
          case "en":
            return "This adventurous world is entirely yours. You can play any game you want.";
          case "ar":
            return "عالم المغامرة هذا ملكك بالكامل. يمكنك لعب أي لعبة تريدها.";
          case "ru":
            return "Этот полный приключений мир полностью принадлежит вам. Вы можете играть в любую игру, которую хотите.";
        }
        break;

      case "joinus":
        switch (language) {
          case "tr":
            return "Haydi sen de Maceraya Katıl!";
          case "en":
            return "Come Join the Adventure!";
          case "ar":
            return "تعال وانضم إلى المغامرة!";
          case "ru":
            return "Присоединяйтесь к приключениям!";
        }
        break;

      case "citywaiting":
        switch (language) {
          case "tr":
            return "Ozmo Adventure Land’de seni büyük bir şehir bekliyor.";
          case "en":
            return "A big city awaits you in Ozmo Adventure Land.";
          case "ar":
            return "تنتظرك مدينة كبيرة في Ozmo Adventure Land.";
          case "ru":
            return "Ozmo Adventure Land вас ждет большой город.";
        }
        break;

      case "downloadnow":
        switch (language) {
          case "tr":
            return "Haydi Hemen İndir!";
          case "en":
            return "Come on Download Now!";
          case "ar":
            return "تعال حمل الآن!";
          case "ru":
            return "Давай Скачать сейчас!";
        }
        break;
    }
  }

  return (
    <>
    
      <video autoplay="autoplay" loop="loop" muted="muted" playsinline="playsinline" id="myVideo">
        <source src="video/ozmo-banner.mp4" type="video/mp4" />
      </video>

      <div className="DASHBOARD">
        <div
          className="iphone-mini"
          style={{
            height: "100vh",
            width: "100%"
          }}
        >
          <div
            style={{
              height: screenWidth >= 600 ? "2500px" : screenWidth < 600 ? "2500px" : undefined,
              position: screenWidth >= 600 ? "relative" : screenWidth < 600 ? "absolute" : undefined,
              top: screenWidth >= 600 ? "-9px" : screenWidth < 600 ? "124px" : undefined,
              width: screenWidth >= 600 ? "100%" : screenWidth < 600 ? "100%" : undefined,
            }}>

            <div
              className="rectangle-2"
              style={{
                height: screenWidth < 600 ? "2800px" : screenWidth >= 600 ? "2500px" : undefined,
                top: screenWidth < 600 ? "213px" : screenWidth >= 600 ? "1089px" : undefined,
                width: screenWidth < 600 ? "100%" : screenWidth >= 600 ? "100%" : undefined,
              }}
            />

            {/* <img
              className="rectangle-1"
              style={{
                height: screenWidth >= 600 ? "1080px" : screenWidth < 600 ? "211px" : undefined,
                left: screenWidth >= 600 ? "113px" : screenWidth < 600 ? "72px" : undefined,
                top: screenWidth >= 600 ? "9px" : screenWidth < 600 ? "0" : undefined,
                width: screenWidth >= 600 ? "100%" : screenWidth < 600 ? "375px" : undefined,
              }}
              alt={"Rectangle"}
              src={
                screenWidth >= 600 ? "/img/rectangle-1.png" : screenWidth < 600 ? "/img/rectangle-1-1.png" : undefined
              }
            /> */}


            {screenWidth < 600 && (
              <>
                <div className="clouds">
                  <div className="overlap-4">
                    <img className="asset-9" alt={"Asset"} src={"/img/asset-9-5.png"} />
                    <img className="asset-8" alt={"Asset"} src={"/img/asset-8-5.png"} />
                    <img className="asset-1" alt={"Asset"} src={"/img/asset-1-1.png"} />
                    <img className="asset-2" alt={"Asset"} src={"/img/asset-2-1.png"} />
                    <img className="asset-3" alt={"Asset"} src={"/img/asset-3-3.png"} />
                    <img className="asset-4" alt={"Asset"} src={"/img/asset-4-1.png"} />
                    <img className="asset-6" alt={"Asset"} src={"/img/asset-6-1.png"} />
                    <img className="asset-7" alt={"Asset"} src={"/img/asset-7-1.png"} />
                    <img className="asset-5" alt={"Asset"} src={"/img/asset-5-1.png"} />
                  </div>
                </div>
                <OzmoCharacter
                  ozmoCharacter="/img/ozmo-character-1-2.png"
                  ozmoCharacterStyle={{
                    height: "66px",
                    top: "2px",
                    width: "70px",
                  }}
                  style={{
                    height: "70px",
                    left: "357px",
                    position: "absolute",
                    top: "149px",
                    width: "70px",
                  }}
                />

                <div className="overlap-wrapper">
                  <img className="icon-cloud" alt={"Icon cloud"} src={"/img/asset-8-4.png"} />
                  <img className="asset-10" alt={"Asset"} src={"/img/asset-9-4.png"} />
                  <img
                    className="OZMO-adventureland-headline"
                    alt={"Ozmo adventureland headline"}
                    src={"/img/ozmo-adventureland-headline_"+language+".png" }
                  />
                  <div class="ozmo-logo-mobile">
                    <img src="/img/ozmo-al-1-2-2.png" />
                  </div>
                  <div className="group-816">
                    <div className="overlap-group4">
                      <div className="rectangle-3" />
                      <CardCloudsFooter
                        asset="/img/asset-8-6.png"
                        assetStyle={{
                          height: "192px",
                          top: "26px",
                          width: "334px",
                        }}
                        groupStyle={{
                          height: "218px",
                          width: "334px",
                        }}
                        overlapGroupStyle={{
                          height: "218px",
                        }}
                        property1="cloud-1-footer"
                        rectangle="/img/rectangle-3-2.svg"
                        rectangleStyle={{
                          height: "183px",
                          width: "334px",
                        }}
                        style={{
                          height: "218px",
                          left: "0",
                          position: "absolute",
                          top: "275px",
                          width: "335px",
                        }}
                      />
                      <ImageCharacter
                        cOutfit="/img/c-outfit-1.png"
                        cOutfitStyle={{
                          height: "277px",
                          left: "56px",
                          width: "156px",
                        }}
                        groupStyle={{
                          height: "277px",
                          left: "31px",
                          top: "43px",
                          width: "259px",
                        }}
                        img="/img/mask-group-13.png"
                        imgStyle={{
                          height: "65px",
                          left: "29px",
                          top: "116px",
                          width: "65px",
                        }}
                        imgStyleOverride={{
                          height: "73px",
                          top: "45px",
                          width: "73px",
                        }}
                        maskGroup="/img/mask-group-12.png"
                        maskGroup1="/img/mask-group-14.png"
                        maskGroup2="/img/mask-group-15.png"
                        maskGroupStyle={{
                          height: "61px",
                          left: "197px",
                          top: "22px",
                          width: "61px",
                        }}
                        maskGroupStyleOverride={{
                          height: "55px",
                          left: "177px",
                          top: "86px",
                          width: "55px",
                        }}
                        overlapGroupStyle={{
                          height: "277px",
                        }}
                        style={{
                          height: "320px",
                          left: "11px",
                          position: "absolute",
                          top: "132px",
                          width: "320px",
                        }}
                      />
                      <div className={"text-wrapper-9 "+(language == "ru" && " ru-size")}>{getWord("createchar")}</div>
                      <p className="en-heyecan-verici-k-s-m-kendi-karakterinizi-yaratabilir-sa-stilini-bile-de-i-tirebilirsiniz">
                        {getWord("createchar2")}
                      </p>
                    </div>
                  </div>
                  <div className="group-817">
                    <div className="overlap-group6">
                      <div className="rectangle-5" />
                      <CardCloudsFooter
                        asset9="/img/asset-9-6.png"
                        assetStyle={{
                          height: "187px",
                          left: "1px",
                          top: "30px",
                          width: "334px",
                        }}
                        groupStyle={{
                          height: "217px",
                          left: "-31px",
                          width: "400px",
                        }}
                        overlapGroupStyle={{
                          height: "217px",
                          left: "31px",
                          width: "335px",
                        }}
                        property1="cloud-2-footer"
                        rectangle4="/img/rectangle-4-2.svg"
                        rectangleStyle={{
                          height: "162px",
                          width: "335px",
                        }}
                        style={{
                          height: "218px",
                          left: "0",
                          position: "absolute",
                          top: "312px",
                          width: "335px",
                        }}
                      />
                      <ImageGames
                        bask="/img/bask-1-2.png"
                        baskStyle={{
                          height: "116px",
                          top: "3px",
                          width: "118px",
                        }}
                        ellipse5Style={{
                          borderRadius: "62.3px",
                          height: "125px",
                          left: "15px",
                          width: "125px",
                        }}
                        ellipseStyle={{
                          borderRadius: "54.6px",
                          height: "109px",
                          left: "27px",
                          top: "9px",
                          width: "109px",
                        }}
                        ellipseStyleOverride={{
                          borderRadius: "67.29px",
                          height: "135px",
                          left: "7px",
                          width: "135px",
                        }}
                        group7Style={{
                          height: "125px",
                          left: "184px",
                          top: "147px",
                          width: "140px",
                        }}
                        group9Style={{
                          height: "135px",
                          left: "129px",
                          width: "149px",
                        }}
                        groupStyle={{
                          height: "271px",
                          left: "-3px",
                          top: "26px",
                          width: "323px",
                        }}
                        groupStyleOverride={{
                          height: "127px",
                          top: "102px",
                          width: "164px",
                        }}
                        maskGroup="/img/mask-group-17.png"
                        maskGroupStyle={{
                          height: "128px",
                          top: "7px",
                          width: "149px",
                        }}
                        overlapGroup1Style={{
                          height: "135px",
                        }}
                        overlapGroup2Style={{
                          height: "125px",
                        }}
                        overlapGroupStyle={{
                          height: "127px",
                          left: "3px",
                          width: "160px",
                        }}
                        overlapStyle={{
                          height: "271px",
                        }}
                        overlapStyleOverride={{
                          height: "229px",
                          width: "278px",
                        }}
                        style={{
                          height: "320px",
                          left: "8px",
                          position: "absolute",
                          top: "152px",
                          width: "320px",
                        }}
                        tarl="/img/tarl-1-2.png"
                        tarlStyle={{
                          height: "127px",
                          width: "160px",
                        }}
                      />
                      <div className={"text-wrapper-11 "+(language == "ru" && " ru-size")}>{getWord("gamesprices")}</div>
                      <p className="text-wrapper-12">
                        {getWord("gamesprices2")}
                      </p>
                    </div>
                  </div>
                  <div className="group-818">
                    <div className="overlap-group5">
                      <div className="rectangle-4" />
                      <CardCloudsFooter
                        asset3="/img/asset-3-4.png"
                        assetStyle={{
                          height: "190px",
                          top: "28px",
                          width: "334px",
                        }}
                        groupStyle={{
                          height: "217px",
                        }}
                        overlapGroupStyle={{
                          height: "217px",
                          width: "335px",
                        }}
                        property1="cloud-3-footer"
                        rectangle5="/img/rectangle-5-2.svg"
                        rectangleStyle={{
                          height: "154px",
                          width: "335px",
                        }}
                        style={{
                          height: "218px",
                          left: "0",
                          position: "absolute",
                          top: "275px",
                          width: "335px",
                        }}
                      />
                      <ImageMarket
                        maskGroup="/img/mask-group-16.png"
                        maskGroupStyle={{
                          height: "256px",
                          left: "20px",
                          top: "32px",
                          width: "280px",
                        }}
                        style={{
                          height: "320px",
                          left: "8px",
                          position: "absolute",
                          top: "129px",
                          width: "320px",
                        }}
                      />
                      <div className={"text-wrapper-10 "+(language == "ru" && " ru-size")}>{getWord("spendpoints")}</div>
                      <p className="yeni-e-yalar-alabilirsin-t-m-bunlar-ozmo-nun-e-lenceli-d-kk-n-nda-sizi-bekliyor">
                        {getWord("spendpoints2")}
                      </p>
                    </div>
                    <div className="group-819">
                      <p className="ozmo-macera-d-nyas-nda-sizi-b-y-k-bir-ehir-kar-l-yor">
                        {getWord("citywaiting")}
                      </p>
                      <h1 className="h-1">Keşfedilecek Bir Şehir</h1>
                      <div className="group-820">
                        <div className="overlap-group-5">
                          <img className="image-008-0003" alt={"Image"} src={"/img/image-008-0003-1.png"} />
                          <OzmoAdventureLandLogo
                            style={{
                              backgroundImage: "url(/img/ozmo-al-1-2-3.png)",
                              height: "40px",
                              left: "128px",
                              position: "absolute",
                              top: "162px",
                              width: "81px",
                            }}
                          />
                        </div>


                        <h1 class="haydi-hemen-indir">
                          {getWord("downloadnow")}</h1>
                      </div>

                      <div class="stores">

                      <a href="https://play.google.com/store/apps/details?id=com.Solen.OzmoAdventureLandMain&hl=tr" target="_blank">
                        <GetOnTheGooglePlayBadgeTr
                          getOnTheGooglePlayBadgeTr="/img/get-on-the-google-play-badge-tr-2.svg"
                          style={{
                            height: "44px",
                            left: "14px",
                            top: "468px",
                            width: "159px",
                          }}
                        /></a>

<a href="https://apps.apple.com/tr/app/ozmo-adventure-land/id6451192799" target="_blank">
                        <DownloadOnTheAppStoreBadgeTr
                          downloadOnTheAppStoreBadgeTr="/img/download-on-the-app-store-badge-tr-2.svg"
                          style={{
                            height: "45px",
                            left: "14px",
                            top: "410px",
                            width: "159px",
                          }}
                        />
                        </a>

                        <div className="group-19">  
                        <img src="/img/ozmo_qr.png" width={157} height={157}/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </>
            )}

            {screenWidth >= 600 && (
              <>
                <header className="HEADER">
                  <div className="HEADER_INNER">
                    <div className="ANASAYFA">
                      <div
                        className="ozmo-adventure-land-logo-wrapper"
                        style={{
                          backgroundImage: "url(/img/rectangle-14.svg)",
                        }}
                      >
                        <OzmoAdventureLandLogo
                          style={{
                            backgroundImage: "url(/img/ozmo-al-1-2.png)",
                            height: "70px",
                            left: "29px",
                            position: "relative",
                            top: "1px",
                            width: "140px",
                          }}
                        />
                      </div>
                    </div>
                    
                    <div className="HAKKINDA">
                      <div
                        className="div-wrapper"
                        style={{
                          backgroundImage: "url(/img/rectangle-15.svg)",
                        }}
                      >
                        <a href="https://ozmo.com.tr/ben-kimim/" target="_blank"><div className="text-wrapper-26">{getWord("about")}</div></a>
                      </div>
                    </div>
                    <div className="LETM">
                      <div
                        className="overlap-5"
                        style={{
                          backgroundImage: "url(/img/rectangle-12.svg)",
                        }}
                      >
                        <a href="https://ozmo.com.tr/iletisim/" target="_blank"><div className="text-wrapper-27">{getWord("contact")}</div></a>
                      </div>
                    </div>
                    <div className="INDIR">
                      <div
                        className="overlap-6"
                        style={{
                          backgroundImage: "url(/img/rectangle-13.svg)",
                        }}
                      >
                        <div className="text-wrapper-28">{getWord("download")}</div>
                        <a href="https://apps.apple.com/tr/app/ozmo-adventure-land/id6451192799" target="_blank">
                        <div className="app-store-icon">
                          <SimpleIconsAppstore
                            simpleIconsAppstore="/img/simple-icons-appstore.svg"
                            style={{
                              left: "unset",
                              minWidth: "24px",
                              position: "relative",
                              top: "unset",
                              width: "unset",
                            }}
                          />
                        </div>
                        </a>

                        <a href="https://play.google.com/store/apps/details?id=com.Solen.OzmoAdventureLandMain&hl=tr" target="_blank">
                        <div className="google-play-icon">
                          <IonLogoGooglePlaystore
                            ionLogoGooglePlaystore="/img/ion-logo-google-playstore.svg"
                            style={{
                              left: "unset",
                              minWidth: "24px",
                              position: "relative",
                              top: "unset",
                              width: "unset",
                            }}
                          />
                        </div>
                          </a>
                      </div>
                    </div>
                  </div>
                </header>

                <div className="overlap-wrapper">
                  <div className="overlap-7">
                    {/* <img className="asset-11" alt={"Asset"} src={"/img/asset-9-1.png"} />
                    <img className="asset-12" alt={"Asset"} src={"/img/asset-8-1.png"} />
                    <img className="asset-13" alt={"Asset"} src={"/img/asset-1.png"} />
                    <img className="asset-14" alt={"Asset"} src={"/img/asset-2.png"} />
                    <img className="asset-15" alt={"Asset"} src={"/img/asset-3.png"} />
                    <img className="asset-16" alt={"Asset"} src={"/img/asset-4.png"} />
                    <img className="asset-17" alt={"Asset"} src={"/img/asset-6.png"} />
                    <img className="asset-18" alt={"Asset"} src={"/img/asset-7.png"} />
                    <img className="asset-19" alt={"Asset"} src={"/img/asset-5.png"} /> */}
                  </div>

                  <img className="asset-20" alt={"Asset"} src={"/img/asset-8.png"} />
                  <img className="asset-21" alt={"Asset"} src={"/img/asset-9.png"} />
                  <div className="PART-WELCOME">
                    <div className="PART-WELCOME_INNER">
                      <div className="CARD">
                        <div className="overlap-8">
                          <div className="rectangle-6" />
                          <CardCloudsFooter
                            asset9="/img/asset-9-2.png"
                            property1="cloud-2-footer"
                            rectangle4="/img/rectangle-4.svg"
                            style={{
                              left: "0",
                              position: "absolute",
                              top: "376px",
                            }}
                          />
                          <p className="text-wrapper-29">
                            {getWord("gamesprices2")}
                          </p>
                          <div className={"text-wrapper-30 "+(language == "ru" && " ru-size")}>{getWord("gamesprices")}</div>
                          <ImageGames
                            bask="/img/bask-1.png"
                            baskStyle={{
                              height: "116px",
                              top: "3px",
                              width: "118px",
                            }}
                            ellipse5Style={{
                              borderRadius: "62.3px",
                              height: "125px",
                              left: "15px",
                              width: "125px",
                            }}
                            ellipseStyle={{
                              borderRadius: "54.6px",
                              height: "109px",
                              left: "27px",
                              top: "9px",
                              width: "109px",
                            }}
                            ellipseStyleOverride={{
                              borderRadius: "67.29px",
                              height: "135px",
                              left: "7px",
                              width: "135px",
                            }}
                            group7Style={{
                              height: "125px",
                              left: "184px",
                              top: "147px",
                              width: "140px",
                            }}
                            group9Style={{
                              height: "135px",
                              left: "129px",
                              width: "149px",
                            }}
                            groupStyle={{
                              height: "271px",
                              left: "-3px",
                              top: "26px",
                              width: "323px",
                            }}
                            groupStyleOverride={{
                              height: "127px",
                              top: "102px",
                              width: "164px",
                            }}
                            maskGroup="/img/mask-group.png"
                            maskGroupStyle={{
                              height: "128px",
                              top: "7px",
                              width: "149px",
                            }}
                            overlapGroup1Style={{
                              height: "135px",
                            }}
                            overlapGroup2Style={{
                              height: "125px",
                            }}
                            overlapGroupStyle={{
                              height: "127px",
                              left: "3px",
                              width: "160px",
                            }}
                            overlapStyle={{
                              height: "271px",
                            }}
                            overlapStyleOverride={{
                              height: "229px",
                              width: "278px",
                            }}
                            style={{
                              height: "320px",
                              left: "40px",
                              position: "absolute",
                              top: "184px",
                              width: "320px",
                            }}
                            tarl="/img/tarl-1.png"
                            tarlStyle={{
                              height: "127px",
                              width: "160px",
                            }}
                          />
                        </div>
                      </div>
                      <div className="overlap-9">
                        <img
                          className="OZMO-adventureland-headline-2"
                          alt={"Ozmo adventureland headline"}
                          src={"/img/ozmo-adventureland-headline_"+language+".png" }
                        />
                        <div class="ozmo-adventure-land-logo-2 ozmo-adventure-land-logo-3"></div>
                      </div>
                      <div className="CARD-1">
                        <div className="overlap-10">
                          <div className="rectangle-7" />
                          <CardCloudsFooter
                            asset="/img/asset-8-2.png"
                            property1="cloud-1-footer"
                            rectangle="/img/rectangle-3.svg"
                            style={{
                              left: "1px",
                              position: "absolute",
                              top: "293px",
                            }}
                          />
                          {/* <Translator
                            cacheProvider={cacheProvider}
                            from='en'
                            to='es'
                            googleApiKey='API_KEY'
                          > */}

                          <div className={"text-wrapper-32 "+(language == "ru" && " ru-size")}>
                            {getWord("createchar")}
                          </div>

                          {/* </Translator> */}
                          <p className="en-heyecan-verici-k-s-m-kendi-karakterinizi-yaratabilir-sa-stilini-bile-de-i-tirebilirsiniz-2">
                            {getWord("createchar2")}
                          </p>
                          <ImageCharacter
                            cOutfit="/img/c-outfit-1-2x.png"
                            cOutfitStyle={{
                              height: "277px",
                              left: "56px",
                              width: "156px",
                            }}
                            groupStyle={{
                              height: "277px",
                              left: "31px",
                              top: "43px",
                              width: "259px",
                            }}
                            img="/img/mask-group-2.png"
                            imgStyle={{
                              height: "65px",
                              left: "29px",
                              top: "116px",
                              width: "65px",
                            }}
                            imgStyleOverride={{
                              height: "73px",
                              top: "45px",
                              width: "73px",
                            }}
                            maskGroup="/img/mask-group-1.png"
                            maskGroup1="/img/mask-group-3.png"
                            maskGroup2="/img/mask-group-4.png"
                            maskGroupStyle={{
                              height: "61px",
                              left: "197px",
                              top: "22px",
                              width: "61px",
                            }}
                            maskGroupStyleOverride={{
                              height: "55px",
                              left: "177px",
                              top: "86px",
                              width: "55px",
                            }}
                            overlapGroupStyle={{
                              height: "277px",
                            }}
                            style={{
                              height: "320px",
                              left: "40px",
                              position: "absolute",
                              top: "168px",
                              width: "320px",
                            }}
                          />
                        </div>
                      </div>
                      <div className="CARD-3">
                        <div className="overlap-group7">
                          <div className="rectangle-8" />
                          <CardCloudsFooter
                            asset3="/img/asset-3-1.png"
                            property1="cloud-3-footer"
                            rectangle5="/img/rectangle-5.svg"
                            style={{
                              left: "0",
                              position: "absolute",
                              top: "293px",
                            }}
                          />
                          <ImageMarket
                            maskGroup="/img/mask-group-5.png"
                            maskGroupStyle={{
                              height: "256px",
                              left: "20px",
                              top: "32px",
                              width: "280px",
                            }}
                            style={{
                              height: "320px",
                              left: "40px",
                              position: "absolute",
                              top: "168px",
                              width: "320px",
                            }}
                          />
                          <div className={"text-wrapper-33 "+(language == "ru" && " ru-size")}>{getWord("spendpoints")}</div>
                          <p className="yeni-e-yalar-alabilirsin-t-m-bunlar-ozmo-nun-e-lenceli-d-kk-n-nda-sizi-bekliyor-2">
                            {" "}
                            {getWord("spendpoints2")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="PART-CITY">

                    <div className="PART-CITY_INNER">
                      <p className="ozmo-macera-d-nyas-nda-sizi-b-y-k-bir-ehir-kar-l-yor-2">
                        {getWord("citywaiting")}
                      </p>
                      <h1 class="haydi-sende-maceraya-katl">{getWord("joinus")}</h1>
                      <div className="group-18">
                        <div className="overlap-group-6">
                          <img className="image-2" alt={"Image"} src={"/img/image-008-0003.png"} />
                          <OzmoAdventureLandLogo
                            style={{
                              backgroundImage: "url(/img/ozmo-al-1-2-1.png)",
                              height: "150px",
                              left: "472px",
                              position: "absolute",
                              top: "601px",
                              width: "300px",
                            }}
                          />
                        </div>
                      </div>



                      <div className="group-2">

                        <h1 class={"haydi-indir "+(language == "ru" && " ru-size-lg")}>
                          {getWord("downloadnow")}</h1>
                        
                          <a href="https://play.google.com/store/apps/details?id=com.Solen.OzmoAdventureLandMain&hl=tr" target="_blank">
                        <GetOnTheGooglePlayBadgeTr
                          getOnTheGooglePlayBadgeTr="/img/get-on-the-google-play-badge-tr.svg"
                          style={{
                            height: "83px",
                            top: "107px",
                            width: "296px",
                          }}
                        />
                        </a>

                        <a href="https://apps.apple.com/tr/app/ozmo-adventure-land/id6451192799" target="_blank">
                        <DownloadOnTheAppStoreBadgeTr
                          downloadOnTheAppStoreBadgeTr="/img/download-on-the-app-store-badge-tr.svg"
                          style={{
                            height: "83px",
                            width: "296px",
                          }}
                        />
                        </a>
                        <div className="qr-kod">
                        <img src="/img/ozmo_qr.png" width={191} height={191}/> </div>
                      </div>
                    </div>
                  </div>
                </div>


                {/* <div className="PART-CARDS">
                  <div className="PART-CARDS_INNER">
                    <div className="overlap-group-wrapper">
                      <div className="overlap-group-7">
                        <div className="text-wrapper-35">Basket atabilir,</div>
                        <ImageBasket
                          basket="/img/basket.png"
                          basketStyle={{
                            height: "314px",
                            left: "124px",
                            top: "17px",
                            width: "97px",
                          }}
                          style={{
                            height: "346px",
                            left: "26px",
                            position: "absolute",
                            top: "70px",
                            width: "346px",
                          }}
                        />
                      </div>
                    </div>
                    <div className="overlap-group1-wrapper">
                      <div className="overlap-11">
                        <div className="text-wrapper-36">Çöp toplayabilir,</div>
                        <ImageClean
                          style={{
                            height: "346px",
                            left: "26px",
                            position: "absolute",
                            top: "70px",
                            width: "346px",
                          }}
                          trash="/img/trash.png"
                          trashStyle={{
                            height: "325px",
                            left: "26px",
                            top: "10px",
                            width: "293px",
                          }}
                        />
                      </div>
                    </div>
                    <div className="overlap-group2-wrapper">
                      <div className="overlap-12">
                        <div className="text-wrapper-37">Bitki yetiştirebilir,</div>
                        <ImagePlant
                          plantto="/img/plantto.png"
                          planttoStyle={{
                            height: "253px",
                            left: "84px",
                            top: "46px",
                            width: "178px",
                          }}
                          style={{
                            height: "346px",
                            left: "26px",
                            position: "absolute",
                            top: "70px",
                            width: "346px",
                          }}
                        />
                      </div>
                    </div>
                    <div className="overlap-group3-wrapper">
                      <div className="overlap-13">
                        <div className="text-wrapper-38">Parkta oynayabilir,</div>
                        <ImagePark
                          playground="/img/playground.png"
                          playgroundStyle={{
                            height: "200px",
                            left: "14px",
                            top: "73px",
                            width: "318px",
                          }}
                          style={{
                            height: "346px",
                            left: "26px",
                            position: "absolute",
                            top: "70px",
                            width: "346px",
                          }}
                        />
                      </div>
                    </div>
                    <div className="overlap-group4-wrapper">
                      <div className="overlap-14">
                        <div className="text-wrapper-39">Hayvanları besleyebilir,</div>
                        <ImagePet
                          catto="/img/catto.png"
                          cattoStyle={{
                            height: "196px",
                            left: "14px",
                            top: "150px",
                            width: "317px",
                          }}
                          style={{
                            height: "346px",
                            left: "26px",
                            position: "absolute",
                            top: "70px",
                            width: "346px",
                          }}
                        />
                      </div>
                    </div>
                    <div className="overlap-group5-wrapper">
                      <div className="overlap-15">
                        <div className="text-wrapper-40">Resim çizebilir,</div>
                        <ImagePaint
                          paint="/img/paint.png"
                          paintStyle={{
                            height: "298px",
                            left: "89px",
                            top: "39px",
                            width: "168px",
                          }}
                          style={{
                            height: "346px",
                            left: "26px",
                            position: "absolute",
                            top: "70px",
                            width: "346px",
                          }}
                        />
                      </div>
                    </div>
                    <div className="overlap-group6-wrapper">
                      <div className="overlap-16">
                        <div className="text-wrapper-41">Piyano çalabilir,</div>
                        <ImagePiano
                          piano="/img/piano.png"
                          pianoStyle={{
                            height: "269px",
                            left: "4px",
                            top: "77px",
                            width: "337px",
                          }}
                          style={{
                            height: "346px",
                            left: "26px",
                            position: "absolute",
                            top: "70px",
                            width: "346px",
                          }}
                        />
                      </div>
                    </div>
                    <div className="overlap-group7-wrapper">
                      <div className="overlap-17">
                        <p className="hatta-ozmo-nun-gizemli-yumurtas-n-bile-a-abilirsiniz-2">
                          Hatta Ozmo&#39;nun gizemli yumurtasını bile açabilirsiniz!
                        </p>
                        <ImageEgg
                          image="/img/image-1.png"
                          imageStyle={{
                            height: "238px",
                            left: "4px",
                            top: "54px",
                            width: "338px",
                          }}
                          style={{
                            height: "346px",
                            left: "241px",
                            position: "absolute",
                            top: "70px",
                            width: "346px",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div> */}

              </>
            )}

            {screenWidth < 600 && (
              <>
                <header className="header">
                  <div className="overlap-19">
                    <a href="https://ozmo.com.tr/ben-kimim/" target="_blank"><div className="text-wrapper-48">{getWord("about")}</div></a>
                    <a href="https://ozmo.com.tr/iletisim/" target="_blank"><div className="text-wrapper-49">{getWord("contact")}</div></a>
                    <div className="group-821">
                      <div className="text-wrapper-50">{getWord("download")}</div>

                      <a href="https://apps.apple.com/tr/app/ozmo-adventure-land/id6451192799" target="_blank">
                      <div className="simple-icons-appstore-wrapper">
                        <SimpleIconsAppstore
                          simpleIconsAppstore="/img/simple-icons-appstore-2.svg"
                          style={{
                            height: "15.43px",
                            left: "unset",
                            minWidth: "15.43px",
                            position: "relative",
                            top: "unset",
                            width: "unset",
                          }}
                        />
                      </div>
                      </a>

                      <a href="https://play.google.com/store/apps/details?id=com.Solen.OzmoAdventureLandMain&hl=tr" target="_blank">
                      <div className="ion-logo-google-playstore-wrapper">
                        <IonLogoGooglePlaystore
                          ionLogoGooglePlaystore="/img/ion-logo-google-playstore-2.svg"
                          style={{
                            height: "15.43px",
                            left: "unset",
                            minWidth: "15.43px",
                            position: "relative",
                            top: "unset",
                            width: "unset",
                          }}
                        />
                      </div>
                      </a>
                    </div>
                    <OzmoAdventureLandLogo
                      style={{
                        backgroundImage: "url(/img/ozmo-al-1-2-4.png)",
                        height: "44px",
                        left: "16px",
                        position: "absolute",
                        top: "18px",
                        width: "88px",
                      }}
                    />
                  </div>
                </header>
              </>
            )}


            <div className="footer">

              <footer id="footer" role="contentinfo" className="footer_content">
                <div className="footer_inner">
                  <div className="footer_logo_wrap" />
                  <div className="social_wrap">
                    <div className="social_wrap_inner">
                      <a href="https://www.youtube.com/channel/UC9Cup5Ulz7EgdHTprTYc_DQ" className="cmsmasters_social_icon cmsmasters_social_icon_1 cmsmasters-icon-youtube-play cmsmasters_social_icon_color" title="YouTube" target="_blank" >
                        <img src="/img/youtube.png"></img>
                      </a>

                      <a href="https://www.facebook.com/ozmoozmoozmo" className="cmsmasters_social_icon cmsmasters_social_icon_2 cmsmasters-icon-facebook-1 cmsmasters_social_icon_color" title="Facebook" target="_blank" >
                        <img src="/img/facebook.png"></img>
                      </a>
                      <a href="https://www.instagram.com/ozmoozmoozmo/?hl=tr" className="cmsmasters_social_icon cmsmasters_social_icon_3 cmsmasters-icon-instagram-1" title="Instagram" target="_blank" >
                        <img src="/img/instagram.png"></img>
                      </a>
                      <a href="https://twitter.com/ozmo_ozmo_ozmo" className="cmsmasters_social_icon cmsmasters_social_icon_4 cmsmasters-icon-custom-twitter-bird-1 cmsmasters_social_icon_color" title="Twitter" target="_blank" >
                        <img src="/img/twitter.png"></img>
                      </a>
                    </div>
                  </div>
                  {/* <a className="footer-logo" target="_blank" href="https://solen.com.tr/"><img src="/img/solen.png" /></a> */}
                  <span className="footer_copyright copyright">© 2023 OZMO, Tüm Hakları Saklıdır. {screenWidth >= 600 ? " " : <br></br>} <a href="/kullanim.html" target="_blank">Kullanım Koşulları</a> | <a href="/gizlilik.html" target="_blank">Gizlilik Politikası</a>

                  <span className="lang-select">
                    <ReactLanguageSelect
                        names={["international"]}
                        languages={["tr","en", "ar", "ru"]}
                        defaultLanguage={language}
                        onSelect={onSelectLanguage}
                         />

                    </span>
                    
                  </span> </div>
              </footer>

            </div>

          </div>
        </div>
      </div></>
  );
};

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const IonLogoGooglePlaystore = ({ style, ionLogoGooglePlaystore = "/img/ion-logo-google-playstore-1.svg" }) => {
  return (
    <img
      className="ion-logo-google-playstore"
      style={style}
      alt={"Ion logo google playstore"}
      src={ionLogoGooglePlaystore}
    />
  );
};

IonLogoGooglePlaystore.propTypes = {
  ionLogoGooglePlaystore: PropTypes.string,
};

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const OzmoCharacter = ({ style, ozmoCharacterStyle, ozmoCharacter = "/img/ozmo-character-1-1.png" }) => {
  return (
    <div className="ozmo-character" style={style}>
      <img className="ozmo-character-1" style={ozmoCharacterStyle} alt={"Ozmo character"} src={ozmoCharacter} />
    </div>
  );
};

OzmoCharacter.propTypes = {
  ozmoCharacter: PropTypes.string,
};

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const ImageCharacter = ({
  style,
  groupStyle,
  overlapGroupStyle,
  maskGroupStyle,
  maskGroup = "/img/mask-group-7.png",
  maskGroupStyleOverride,
  img = "/img/mask-group-8.png",
  imgStyle,
  maskGroup1 = "/img/mask-group-9.png",
  imgStyleOverride,
  maskGroup2 = "/img/mask-group-10.png",
  cOutfitStyle,
  cOutfit = "/img/c-outfit-1-1.png",
}) => {
  return (
    <div className="image-character" style={style}>
      <img src="/img/image-character.svg"/>
    </div>
  );
};

ImageCharacter.propTypes = {
  maskGroup: PropTypes.string,
  img: PropTypes.string,
  maskGroup1: PropTypes.string,
  maskGroup2: PropTypes.string,
  cOutfit: PropTypes.string,
};

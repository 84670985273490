import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const ImageMarket = ({ style, maskGroupStyle, maskGroup = "/img/mask-group-6.png" }) => {
  return (
    <div className="image-market" style={style}>
      <img className="mask-group-4" style={maskGroupStyle} alt={"Mask group"} src={maskGroup} />
    </div>
  );
};

ImageMarket.propTypes = {
  maskGroup: PropTypes.string,
};

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const SimpleIconsAppstore = ({ style, simpleIconsAppstore = "/img/simple-icons-appstore-1.svg" }) => {
  return (
    <img className="simple-icons-appstore" style={style} alt={"Simple icons appstore"} src={simpleIconsAppstore} />
  );
};

SimpleIconsAppstore.propTypes = {
  simpleIconsAppstore: PropTypes.string,
};

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const CardCloudsFooter = ({
  property1,
  style,
  groupStyle,
  overlapGroupStyle,
  rectangleStyle,
  rectangle = "/img/rectangle-3-1.svg",
  assetStyle,
  asset = "/img/asset-8-3.png",
  rectangle5 = "/img/rectangle-5-1.svg",
  asset3 = "/img/asset-3-2.png",
  rectangle4 = "/img/rectangle-4-1.svg",
  asset9 = "/img/asset-9-3.png",
}) => {
  return (
    <div className={`card-clouds-footer ${property1}`} style={style}>
      <div className={`group property-1-${property1}`} style={groupStyle}>
        <div className={`overlap-group property-1-0-${property1}`} style={overlapGroupStyle}>
          {/* <img
            className={`rectangle property-1-1-${property1}`}
            style={rectangleStyle}
            alt={"Rectangle"}
            src={property1 === "cloud-2-footer" ? rectangle4 : property1 === "cloud-3-footer" ? rectangle5 : rectangle}
          /> */}
          {/* <img
            className={`asset property-1-2-${property1}`}
            style={assetStyle}
            alt={"Asset"}
            src={property1 === "cloud-2-footer" ? asset9 : property1 === "cloud-3-footer" ? asset3 : asset}
          /> */}
        </div>
      </div>
    </div>
  );
};

CardCloudsFooter.propTypes = {
  property1: PropTypes.oneOf(["cloud-3-footer", "cloud-1-footer", "cloud-2-footer"]),
  rectangle: PropTypes.string,
  asset: PropTypes.string,
  rectangle5: PropTypes.string,
  asset3: PropTypes.string,
  rectangle4: PropTypes.string,
  asset9: PropTypes.string,
};
